import { CalendarPlus } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const CardContainer = styled.div<{ $isFuture?: boolean }>`
  width: 100%;
  justify-content: center;
  box-shadow: ${({ theme, $isFuture }) =>
    $isFuture ? theme.shadows.container.glowYellow : theme.shadows.card.level1};
  border-radius: 16px;
  overflow: hidden;
  padding: 32px 48px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ $isFuture, theme }) => `
    background-color: ${
      $isFuture ? theme.colors.yellow.lightestYellow : theme.deprecatedColors.grayLight
    };
    `}
`;

export const FutureActionsWrapper = styled.div`
  width: 100%;
  align-self: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RescheduledText = styled(Text)`
  margin-top: 1.5em;
  text-align: center;
`;

export const AddToCalendarButton = styled(CalendarPlus).attrs({
  type: 'solid'
})``;
