import { CalendarCheck } from 'assets/icons/Icons';
import { Heading } from 'components/v2/Typography';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $centerAlign?: boolean }>`
  ${({ $centerAlign }) => {
    if ($centerAlign) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `;
    }
  }}
`;

export const DateTimeHeadline = styled(Heading).attrs({
  styledAs: 'h1',
  tag: 'div',
  noMargin: true
})<{ $centerAlign?: boolean }>`
  ${({ $centerAlign }) => $centerAlign && `justify-content: center;`}
`;

export const HeaderCalendarIcon = styled(CalendarCheck)<{ $isFuture?: boolean }>`
  border-radius: 50%;
  padding: 15px;
  width: 24px;
  height: 24px;
  background-color: ${({ theme, $isFuture }) =>
    $isFuture ? theme.colors.yellow.lighterYellow : theme.colors.neutral.lavender};
  color: ${({ theme, $isFuture }) =>
    $isFuture ? theme.colors.yellow.darkYellow : theme.colors.neutral.lavenderLight};
  margin-bottom: 8px;
`;

export const AppointmentTime = styled.div`
  font-size: 36px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const AppointmentName = styled(Heading).attrs({ styledAs: 'h4', tag: 'div' })`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;
