import React from 'react';

import { getTimeZone } from 'hooks/getTimeZone';
import { Appointment, AppointmentType } from 'kb-shared';

import {
  Container,
  HeaderCalendarIcon,
  AppointmentTime,
  AppointmentName,
  DateTimeHeadline
} from './AppointmentDetails.styled';

interface Props {
  appointment: Appointment;
  appointmentType: AppointmentType;
  isFuture: boolean;
  hideTopIcon?: boolean;
  centerAlign?: boolean;
}

export const AppointmentDetails = ({
  appointment,
  appointmentType,
  isFuture,
  hideTopIcon,
  centerAlign
}: Props) => {
  const { formatTime } = getTimeZone(appointment.timeZone, appointmentType);

  return (
    <Container $centerAlign={centerAlign}>
      {!hideTopIcon && <HeaderCalendarIcon $isFuture={isFuture} />}

      <AppointmentTime>
        <DateTimeHeadline $centerAlign={centerAlign}>
          {formatTime(appointment.startTime, 'dddd, ')}
        </DateTimeHeadline>
        <DateTimeHeadline $centerAlign={centerAlign}>
          {formatTime(appointment.startTime, 'MMMM Do')}
        </DateTimeHeadline>
        <DateTimeHeadline $centerAlign={centerAlign}>
          {formatTime(appointment.startTime, 'h:mm A z')}
        </DateTimeHeadline>
      </AppointmentTime>
      <AppointmentName>{appointmentType.name}</AppointmentName>
    </Container>
  );
};
