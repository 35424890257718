import React from 'react';

import { Initials } from 'components/Initials/Initials';
import { Appointment } from 'kb-shared';

import {
  Provider,
  ProviderInfo,
  ProviderProfilePicture,
  BookedWithText,
  ProviderNameContainer,
  ProviderLabel,
  InitialsAndNameHolder,
  ProviderName
} from './ProviderDetails.styled';

interface Props {
  provider?: Appointment['provider'];
}

export const ProviderDetailsConfirmation = ({ provider }: Props) => {
  if (!provider) return null;

  return (
    <ProviderInfo>
      <Provider>
        {provider.profilePictureUrl && (
          <ProviderProfilePicture src={provider.profilePictureUrl ?? ''} alt={provider.name} />
        )}

        <BookedWithText absolute={Boolean(provider.profilePictureUrl)}>
          Booked with {provider.name}
        </BookedWithText>
      </Provider>
    </ProviderInfo>
  );
};

export const ProviderDetailsRegular = ({ provider }: Props) => {
  if (!provider) return null;

  return (
    <ProviderNameContainer>
      <ProviderLabel>Provider:</ProviderLabel>
      <InitialsAndNameHolder>
        <Initials name={provider.name} size="sm-md" />
        <ProviderName>{provider.name}</ProviderName>
      </InitialsAndNameHolder>
    </ProviderNameContainer>
  );
};
