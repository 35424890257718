import { Heading, Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const ProviderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
`;

export const Provider = styled.div`
  position: relative;
  padding: 0 32px;
  margin: auto;
`;

export const ProviderProfilePicture = styled.img`
  border-radius: 50%;
  width: 320px;
  height: 320px;
  object-fit: cover;
`;

export const BookedWithText = styled(Heading).attrs({
  styledAs: 'h5',
  tag: 'span',
  noMargin: true
})<{ absolute?: boolean }>`
  ${({ absolute }) => {
    if (absolute)
      return `
      position: absolute;
      bottom: 32px;
      left: 0;
      right: 0;
      margin: auto;
    `;

    return `margin: 12px 0;`;
  }};
  width: fit-content;
  max-width: 100%;
  padding: 8px 16px;
  word-break: break-word;
  text-align: center;
  transform: rotate(-5deg);
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  box-shadow: ${({ theme }) => theme.shadows.card.level2};
`;

export const ProviderNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
`;

export const ProviderLabel = styled(Text).attrs({ tag: 'span', size: 'sm', fontStyle: 'medium' })`
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

export const InitialsAndNameHolder = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

export const ProviderName = styled(Text).attrs({ tag: 'span', size: 'md', fontStyle: 'semibold' })`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;
