import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

// Note:
// it's important that we import the body-scroll-lock functions from a single place
// Check the comments in that file for more details.
import { disableBodyScroll, enableBodyScroll } from 'components/Modal/BaseModal';

// This component implicitly depends on the src/components/Modal/styles.scss styles for overlay class styles

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
}

interface Props extends Omit<ReactModal['props'], 'portalClassName' | 'isOpen' | 'contentLabel'> {
  open: boolean;
  label?: string;
  className?: string;
  modalClassName?: string;
}

export const BaseModal = ({ className, modalClassName, open, label, ...rest }: Props) => {
  const [overlayElement, setOverlayElement] = useState<HTMLElement>();

  useEffect(() => {
    if (!overlayElement) return;

    const modalContentElement = overlayElement.querySelector(`.${modalClassName}`);

    if (!modalContentElement) return;

    if (open) {
      disableBodyScroll(modalContentElement);
    } else {
      enableBodyScroll(modalContentElement);
    }

    return () => modalContentElement && enableBodyScroll(modalContentElement);
  }, [overlayElement, open, modalClassName]);

  return (
    <ReactModal
      portalClassName={className}
      className={modalClassName}
      overlayRef={setOverlayElement}
      contentLabel={label}
      isOpen={open}
      {...rest}
    />
  );
};
