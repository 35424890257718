import { gql, useQuery } from '@apollo/client';

import { Appointment } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { UrlParamValue } from '../types/types';
import { isFalsyNonNumber } from '../utils/validation';

const QUERY_BOOKED_APPOINTMENT = gql`
  query appointment($appointmentId: Int!) {
    appointment(appointmentId: $appointmentId) {
      id
      videoLink
      location {
        id
        address
        name
        patientFacing
        timeZone
      }
      startTime
      endTime
      timeZone
      cancelable
      reschedulable
      provider {
        name
        profilePictureUrl
      }
      intakeFormRequired
      appointmentType {
        id
        virtual
        virtualFert
        name
        description
        videoLink
        rateCents
        channel
        category {
          id
          name
        }
      }
    }
  }
`;

export const useAppointment = (appointmentId?: UrlParamValue<number>) => {
  const { data, loading, error } = useQuery<{ appointment: Appointment }>(
    QUERY_BOOKED_APPOINTMENT,
    {
      skip: isFalsyNonNumber(appointmentId),
      variables: {
        appointmentId: appointmentId
      },
      onError: error => {
        BugTracker.notify(error, 'Failed to load appointment');
      }
    }
  );

  return {
    data: data?.appointment,
    loading,
    error
  };
};
